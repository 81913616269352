//SideBar
.clearfix:before,
.clearfix:after {
    display: table;
    content: " ";
}

.clearfix:after {
    clear: both;
}

.codrops-header,
.codrops-top {
    font-family: 'Lato', Arial, sans-serif;
}

.codrops-header {
    margin: 0 auto 3em;
    padding: 3em;
    text-align: center;
}

.codrops-header h1 {
    margin: 0;
    font-weight: 300;
    font-size: 2.625em;
    line-height: 1.3;
}

.codrops-header span {
    display: block;
    padding: 0 0 0.6em 0.1em;
    font-size: 60%;
    color: #aca89a;
}

/* To Navigation Style */
.codrops-top {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.69em;
    line-height: 2.2;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.3);
}

.codrops-top a {
    display: inline-block;
    padding: 0 1em;
    text-decoration: none;
    letter-spacing: 0.1em;
}

.codrops-top a:hover {
    background: rgba(255, 255, 255, 0.4);
    color: #333;
}

.codrops-top span.right {
    float: right;
}

.codrops-top span.right a {
    display: block;
    float: left;
}

.codrops-icon:before {
    margin: 0 4px;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: 'codropsicons';
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
    content: "\e001";
}

.codrops-icon-prev:before {
    content: "\e004";
}

.main {
    max-width: 69em;
    margin: 0 auto;
}

.column {
    float: left;
    width: 50%;
    padding: 0 2em;
    min-height: 300px;
    position: relative;
    text-align: right;
}

.column:nth-child(2) {
    box-shadow: -1px 0 0 rgba(0, 0, 0, 0.1);
    text-align: left;
}

.column p {
    font-weight: 300;
    font-size: 2em;
    padding: 0 0 0.5em;
    margin: 0;
    line-height: 1.5;
}

.info {
    text-align: center;
    font-size: 1.5em;
    margin-top: 3em;
    clear: both;
    padding: 3em 0;
    opacity: 0.7;
    color: #aca89a;
}

.info a {
    font-weight: 700;
    font-size: 0.9em;
}

@media screen and (max-width: 46.0625em) {
    .column {
        width: 100%;
        min-width: auto;
        min-height: auto;
        padding: 2em;
        text-align: center;
    }

    .column p {
        font-size: 1.5em;
    }

    .column:nth-child(2) {
        text-align: center;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 25em) {

    .codrops-header {
        font-size: 80%;
    }

    .codrops-top {
        font-size: 120%;
    }

    .codrops-icon span {
        display: none;
    }

}

// html,
// body,
// .st-container,
// .st-pusher {
//     height: 100%;
// }

.st-content {
    overflow-y: scroll;
    background: #f3efe0;
}

.st-content,
.st-content-inner {
    position: relative;
}

.st-container {
    position: relative;
}

.st-pusher {
    position: relative;
    left: 0;
    z-index: 99;
    height: 100%;
    perspective: 1000px;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
}

.st-pusher::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-open .st-pusher::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.st-menu-open {
    overflow: hidden;
}

.st-menu {
    direction: rtl;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
}

.st-menu::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    content: '';
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.st-menu-open .st-menu::after {
    width: 0;
    height: 0;
    opacity: 0;
    -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

/* content style */
.st-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.st-menu ul li a {
    display: block;
    padding: 1em 1em 1em 1.2em;
    outline: none;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
    color: #f3efe0;
    text-transform: uppercase;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
    letter-spacing: 1px;
    font-weight: 400;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
}

.st-menu ul li:first-child a {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2), inset 0 1px rgba(0, 0, 0, 0.2);
}

.st-menu ul li a:hover {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
    color: #fff;
}

/* Effect 3: Push*/
.st-effect-3.st-menu-open .st-pusher {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);

    @media (max-width: 720px) {
        -webkit-transform: translate3d(0, 550px, 0);
        transform: translate3d(0, 550px, 0);
    }
}

.st-effect-3.st-menu {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

.st-effect-3.st-menu-open .st-effect-3.st-menu {
    visibility: visible;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    opacity: 1;
}

.st-effect-3.st-menu::after {
    display: none;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
    padding-left: 300px;
}